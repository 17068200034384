<template>
  <div class="row">
    <form class="form col-xs-12">
      <div class="form__group">
        <label for="firstName" class="form__label">First Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="firstName"
          v-model="user.first_name"
          name="first_name"
        />
      </div>

      <div class="form__group">
        <label for="lastName" class="form__label">Last Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Doe"
          id="lastName"
          v-model="user.last_name"
          name="last_name"
        />
      </div>

      <div class="form__group">
        <label for="email" class="form__label">Email Address</label>
        <input
          type="email"
          class="form__input"
          placeholder="e.g Johanathandoe@gmail.com"
          id="email"
          v-model="user.email"
          name="email"
        />
      </div>

      <div class="form__group">
        <label for="default_role" class="form__label">Role</label>
        <select
          name="default_role"
          id="default_role"
          class="form__input"
          v-model="user.role_id"
        >
          <option disabled value="">Select User Role</option>
          <option
            v-for="role in roles"
            :key="role.id"
            :selected="role.id === user.role_id"
            :value="role.id"
          >
            {{ role.display_name }}
          </option>
        </select>
      </div>

      <!--      <div class="form__group">-->
      <!--        <label for="role" class="form__label">Default Role</label>-->
      <!--        <input-->
      <!--          type="text"-->
      <!--          class="form__input"-->
      <!--          placeholder="e.g Startup"-->
      <!--          id="role"-->
      <!--          v-model="user.role"-->
      <!--          name="role"-->
      <!--        />-->
      <!--      </div>-->

      <div class="form__group">
        <ca-button @click.native="editUser" :disabled="loading">
          <span>Save Changes</span>
          <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
        </ca-button>
      </div>
    </form>
  </div>
</template>

<script>
import CaButton from "../../../components/buttons/CaButton";
import axios from "@/axios";

export default {
  name: "EditUser",
  components: { CaButton },
  data() {
    return {
      loading: false,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        role_id: 0,
      },
      roles: [],
    };
  },
  methods: {
    getUser() {
      const loading = this.$vs.loading();
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/user/${this.$route.params.id}`
        )
        .then((res) => {
          const data = res.data.data.user;
          data.first_name = data.first_name? data.first_name : data.name.split(" ")[0]
          data.last_name = data.last_name? data.last_name : data.name.split(" ")[1]
          this.setUserInfo(data);
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },

    editUser() {
      this.loading = true;
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/user/${this.$route.params.id}`,
          this.user
        )
        .then((res) => {
          this.$toast("User updated successfully", {
            position: "top-center",
            type: "success",
          });
          this.loading = false;
          const data = res.data.data;

          this.setUserInfo(data);
          console.log(data);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    setUserInfo(data) {
      this.user.first_name = data.first_name;
      this.user.last_name = data.last_name;
      this.user.email = data.email;
      this.user.role_id = data.role_id;
    },
    getRoles() {
      axios
        .get(`${this.$store.state.server.requestUri}/admin/roles`)
        .then((res) => {
          this.roles = res.data.data;
        })
        .catch((err) => console.log(err.response));
    },
  },
  created() {
    this.getUser();
    this.getRoles();
  },
};
</script>

<style scoped></style>
